// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Aegis from '@tencent/aegis-web-sdk/lib/aegis.f.min';
import { env as currentEnv } from '@/env/current';
import { DeployEnv } from '@/env';

// ResizeObserver loop limit exceeded错误，不需要上报 https://km.woa.com/group/51926/articles/show/526802
// "Error: 此报错可以忽略，iframe主动中断主应用代码在子应用运行" 无极内嵌的错误
// SiteAxiosInterceptorError 是现有用法 ajax 请求直接抛出
// 不要过滤 Script error. @ (:0:0) 尽量想办法分析
const noReportMsgReg = /(ResizeObserver loop limit exceeded|ResizeObserver loop completed with undelivered notifications|此报错可以忽略|SiteAxiosInterceptorError|Promise was canceled|Unable to preload)/;

const getAgisEnv = () => {
  // 留意，使用无开发者工具干扰的环境
  switch (currentEnv.deployEnv) {
    case DeployEnv.PROD:
      return 'production';
    case DeployEnv.LOCAL:
      return 'local';
    default:
      // 其余均视为 test，实践中是 test/tde
      return 'test';
  }
};

const MAX_MSG_LENGTH = 500;

// 对于 str 形态的 json，尽可能缩减空格，疑似 tam 长度过长时上报接口会报错
const trimJsonString = (str: string) => {
  try {
    return JSON.stringify(JSON.parse(str));
  } catch {
    return str;
  }
};

export const aegis = new Aegis({
  id: 'okaqLTbv2kgaX7JXyo', // 项目key
  reportApiSpeed: true, // 接口测速
  reportAssetSpeed: true, // 静态资源测速
  spa: true, // spa 页面需要开启，页面切换的时候上报pv
  hostUrl: 'https://rumt-sg.com',
  env: getAgisEnv(),
  // 过滤不需要上报的内容
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  beforeRequest(data: any) {
    if (data.logType === 'log') {
      if (data.logs.code === 401 && /trpc\.tab\.auth_center\.AuthCenter/.test(data.logs.msg)) {
        // 此服务接口 401 也视为正常
        return false;
      }
      if (noReportMsgReg.test(data.logs.msg)) {
        return false;
      }
    }
  },
  api: {
    apiDetail: true,
    // retCodeHandler: Function(data: String??, url: String, xhr: Object):{isErr: boolean, code: string}
    // 返回码上报钩子函数。 会传入接口返回数据,请求url和xhr对象;
    retCodeHandler(data: never, requestUrl: string, xhr: XMLHttpRequest) {
      const isHttpError = !(xhr.status >= 200 && xhr.status < 300);

      if (!(/api[.-]/.test(requestUrl) && /abetterchoice[.-]/.test(requestUrl))) {
        // 非 abc 自己的接口按 http 语义即可
        return { isErr: isHttpError, code: `${xhr.status}` };
      }

      if (isHttpError) {
        if (xhr.status === 401) {
          // 当前用法未很好的处理未登录或登录态过期，视为成功不报警
          return { isErr: false, code: xhr.status };
        }
        // 5xx 等
        return { isErr: true, code: xhr.status };
      }

      if (typeof data === 'string') {
        // 约定的接口成功场景
        if (/("status":\s*"(success|info)"|"status":\s*""|"code":\s*0)/.test(data)) {
          return { isErr: false, code: '0' };
        }
        // 额外的白名单场景
        if (xhr.responseURL.includes('/api/auth/auth0token') && /"token":\s*"[^"]+"/.test(data)) {
          return { isErr: false, code: '0' };
        }
        return { isErr: true, code: trimJsonString(data).slice(0, MAX_MSG_LENGTH) };
      }

      // 未知形态的视为错误，收集 case
      return { isErr: true, code: `${data}`.slice(0, MAX_MSG_LENGTH) };
    },
  },
  // 上报 PV 的 url 主动聚类
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onBeforeRequest(log: any) {
    if (log.type === 'pv' && typeof log.url === 'string') {
      const urlObj = new URL(log.url);
      const fromUrl = urlObj.searchParams.get('from');
      if (fromUrl) {
        // 先只过滤 url path param，不包含 query，再观察
        urlObj.searchParams.set('from', fromUrl.replace(/(?<=[/])(\d+)(?=[/?$])/g, '*'));
        // eslint-disable-next-line no-param-reassign
        log.url = urlObj.toString();
      }
    }
    return log;
  },
  // 测速不抽样
  speedSample: false,
  // 对应的代码版本，取构建号或镜像号
  version: Array.from(document.getElementsByTagName('meta'))
    .find(x => x.name === 'x-release-id')
    ?.getAttribute('content') || undefined,
});

window.aegis = aegis;
