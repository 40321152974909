// 业务平台标识，当前产品设计对不同平台有差异化处理、
export enum BizPlatform {
  GCP = 'gcp',
  SG = 'sg',
}

export const BizPlatformText = {
  [BizPlatform.GCP]: 'GCP',
  [BizPlatform.SG]: 'SG',
};

// 代码的部署环境
export enum DeployEnv {
  LOCAL = 'local',
  TEST = 'test',
  PROD = 'prod'
}

export const DeployEnvText = {
  [DeployEnv.LOCAL]: '本地环境',
  [DeployEnv.TEST]: '测试环境',
  [DeployEnv.PROD]: '生产环境',
};
