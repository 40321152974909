import 'dayjs/locale/zh-cn';
import dayjs from 'dayjs';
import webSDKCore from '@tencent/ti18n-websdkcore';
import { Language, I18N_KEY } from './constants';

const lng = localStorage.getItem(I18N_KEY) || Language.EN;
dayjs.locale(lng);

export const initI18n = async () => {
  document.documentElement.lang = {
    en: 'en-US',
    'zh-CN': 'zh-CN',
    ja: 'ja',
  }[lng || Language.EN] || 'en-US';

  try {
    await webSDKCore.init({
      appid: '60319', // 应用id，用于访问CDN线上配置的标识符
      ns: ['665'], // 命名空间
      defaultNS: ['665'], // 默认命名空间
      lng,
      load: 'currentOnly',
      partialBundledLanguages: false, // 部分从sdk传入，部分从connector获取
      debug: false, // 是否开启调试
      // 默认配置
      // 效果为定期请求  {sCdnPrefix}/ti18n/{appid}/{sVersion}/{ns}/{lang}.js?_=${Date.now()时间戳}
      // backend: {
      //   sCdnPrefix: 'https://abc-i18n-1258344701.cos.accelerate.myqcloud.com',
      //   sVersion: '545',
      // }
      // 调整为额外的 CDN 域名边缘节点加速，不使用?时间戳无视缓存强制刷新
      // 具体缓存策略，边缘节点 1h 过期，浏览器 no-cache
      // https://cdn.abetterchoice.ai 配置了 /t18n/* 到 https://abc-i18n-1258344701.cos.accelerate.myqcloud.com/*
      // 具体见 CDN /t18n/* 配置
      backend: {
        sCdnPrefix: 'https://cdn.abetterchoice.ai',
        sVersion: '545',
        iRefreshCdn: false,
      },
      keySeparator: '%#%#',
      nsSeparator: '!#!#',
      pluralSeparator: '_#_#',
      contextSeparator: '_#_#',
      cache: {
        iEnabled: true,
        expires: 1800,
      },
      initImmediate: true,
    });
  } catch {
    // do nothing
  }
};

export const $t = webSDKCore.t.bind(webSDKCore);

export const LOCALE = lng;

window.$t = $t;

export default webSDKCore;
