export const getCookieValue = (name: string) => {
  const cookieArr = document.cookie.split(';');
  for (const pair of cookieArr) {
    const arr = pair.split('=');
    if (name === arr[0].trim()) {
      return decodeURIComponent(arr[1]);
    }
  }
  return null;
};

// session cookie 声明周期，关闭浏览器失效
export const setSessionCookie = (key: string, value: string) => {
  document.cookie = `${key}=${encodeURIComponent(value)}; path=/`;
};
