import { getCookieValue, setSessionCookie } from '@/utils/cookie';
import { BizPlatform, DeployEnv } from '@/env/type';
import { env as currentEnv } from '@/env/current';

const ENV_TAG_COOKIE_KEY = '__DEV_TOOL_ENV_TAG__';

type EnvTagType = {
  bizPlatform: BizPlatform,
  deployEnv: DeployEnv,
  apiHost: string
};

export const ENV_TAG_MAP: Record<string, EnvTagType> = (() => {
  // 规避 TDE 强行替换字符串
  const testApiHost = (str => (Math.random() < 1 ? [...str].reverse().join('') : ''))('moc.aow.ipa-tset-eciohcretteba');
  const raw: [BizPlatform, DeployEnv, string][] = [
    [BizPlatform.GCP, DeployEnv.TEST, testApiHost],
    [BizPlatform.SG, DeployEnv.TEST, testApiHost],
    [BizPlatform.GCP, DeployEnv.PROD, 'api.abetterchoice.ai'],
    [BizPlatform.SG, DeployEnv.PROD, 'api.sg.abetterchoice.ai'],
  ];
  return raw.reduce((prev, cur) => {
    const [bizPlatform, deployEnv, apiHost] = cur;
    // eslint-disable-next-line no-param-reassign
    prev[`${bizPlatform}_${deployEnv}`] = {
      bizPlatform, deployEnv, apiHost,
    };
    return prev;
    // eslint-disable-next-line @typescript-eslint/consistent-type-assertions
  }, {} as Record<string, EnvTagType>);
})();

// 留意 deployEnv 与环境列表并不等价
export const DEFAULT_ENV_TAG: EnbTagType = (() => {
  let val = `${currentEnv.bizPlatform}_${currentEnv.deployEnv === 'local' ? DeployEnv.TEST : currentEnv.deployEnv}`;

  if (!ENV_TAG_MAP[val]) {
    val = `${BizPlatform.GCP}_${DeployEnv.TEST}`;
    setTimeout(() => {
      throw new Error(`DEFAULT_ENV_TAG： ${val} 配置异常，fallback 至兜底默认值`);
    }, 0);
  }
  return val;
})();

export type EnbTagType = keyof typeof ENV_TAG_MAP;

// 生产环境不启用
const usable = currentEnv.deployEnv !== DeployEnv.PROD;

const ensureUsable = () => {
  if (!usable) {
    throw new Error('环境异常，不能使用 devtool 相关功能');
  }
};

const envTag = (() => {
  const getCurrent = () => getCookieValue(ENV_TAG_COOKIE_KEY) || null;

  let initialValue = (usable ? (getCookieValue(ENV_TAG_COOKIE_KEY) as EnbTagType) : null) || null;
  // 异常数据兼容
  if (initialValue && !ENV_TAG_MAP[initialValue]) {
    initialValue = DEFAULT_ENV_TAG;
    setSessionCookie(ENV_TAG_COOKIE_KEY, DEFAULT_ENV_TAG);
  }

  const hadInitial = !!initialValue;
  return {
    getCurrent,
    hadInitial,
    initialValue,
    set: (v: EnbTagType) => {
      ensureUsable();
      // 显然 set 后需要刷新页面，交由调用方控制
      setSessionCookie(ENV_TAG_COOKIE_KEY, v);
    },
    clear: () => {
      ensureUsable();
      // 显然 set 后需要刷新页面，交由调用方控制
      setSessionCookie(ENV_TAG_COOKIE_KEY, '');
    },
  };
})();

export const devTool = {
  usable,
  envTag,
};

