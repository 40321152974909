import { BizPlatform, DeployEnv } from './type';

// 不考虑 devtool 的最终 env，标识了环境相关的各信息
export const env = (() => {
  // 本地开发时，index.html 内的 __SERVER_ASSIGN_xxx__ ejs 未替换，为 "<%= xxx %>"
  const serverAssignApiHost = window.__SERVER_ASSIGN_API_HOST__.includes('<%') ? undefined : window.__SERVER_ASSIGN_API_HOST__;
  const serverAssignBizPlatform = window.__SERVER_ASSIGN_BIZ_PLATFORM__.includes('<%') ? undefined : window.__SERVER_ASSIGN_BIZ_PLATFORM__;
  const serverAssignDeployEnv = window.__SERVER_ASSIGN_DEPLOY_ENV__.includes('<%') ? undefined : window.__SERVER_ASSIGN_DEPLOY_ENV__;

  // 按 server 运行时环境变量 > vite 构建时编译时常量 的优先级使用
  const ret:  {
    apiHost: string,
    bizPlatform: BizPlatform,
    deployEnv: DeployEnv
  } =  {
    apiHost: serverAssignApiHost || import.meta.env.VITE_API_HOST,
    bizPlatform: serverAssignBizPlatform || import.meta.env.VITE_BIZ_PLATFORM,
    deployEnv: serverAssignDeployEnv || import.meta.env.VITE_DEPLOY_ENV,
  };
  // 极端情况下的异常兼容，防止整站挂掉
  if (Object.values(ret).some(x => !x)) {
    setTimeout(() => {
      throw new Error('环境配置异常，fallback 到默认，实际业务已异常');
    }, 0);
    if (!ret.apiHost) {
      ret.apiHost = 'api.abetterchoice.ai';
    }
    if (!ret.bizPlatform) {
      ret.bizPlatform = BizPlatform.GCP;
    }
    if (!ret.deployEnv) {
      ret.deployEnv = DeployEnv.PROD;
    }
  }
  return ret;
})();
